import "./App.css";
import * as React from 'react';
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import Home from "./pages/Home";
import LoginHub from "./pages/Login/LoginHub";
import About from "./pages/About";
import Features from "./pages/Features";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import CampaignHeader from "./common/components/CampaignHeader";
import Header from "./common/components/Header";
import Footer from "./common/components/Footer";
import CampaignFooter from "./common/components/CampaignFooter";
import scroll from "./assets/images/rocket.png";
import { Helmet } from "react-helmet";
import Alerts from "./common/components/Alerts";
import CampaignHome from "./pages/Campagin/CampaignHome"
import AddNewCampaign from "./pages/Campagin/AddNewCampaignHub/AddNewCampaign"
import EditPayment from "./pages/Campagin/Settings/EditPayment"
import EditProfile from "./pages/Campagin/Settings/EditProfile"
import CampaignSites from "./pages/Campagin/CampaignSites";
import { useNavigate } from "react-router-dom";
import { fetchBlogPosts } from "./api/MarketingApi";
import Blog from "./pages/Blog/Blog";
import BlogPostContent from "./pages/Blog/BlogPostContent";

export const RequireAuth = ({ children }) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" replace={true} />
  }

  return children
};

function App() {
  //For iPhone users to be able to use the hamburger menu
  document.addEventListener("touchstart", function () { }, false);

  const location = useLocation();
  window.axios = axios;
  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Alert states
  const [alertState, setAlertState] = React.useState({
    success: false,
    error: false,
    warning: false,
    info: false,
    none: false,
  });

  // Alert Message
  const [alertMessage, setAlertMessage] = React.useState("");

  const onTriggerAlert = (flag) => {

    setAlertState({
      ...alertState,
      [flag]: !alertState[flag],
    });

    // Cleanup alert after 5 secs
    setTimeout((flag) => {
      setAlertState({
        ...alertState,
        [flag]: !alertState[flag],
      })
    }, "4000");
  }

  const [blogList, setBlogList] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  let FetchPosts = async () => {
    let resp = await fetchBlogPosts({});

    setBlogList(resp)
    setIsLoading(false)
  }

  React.useEffect(() => {
    try {
      FetchPosts()
    }
    catch (error) {
      console.log(error);
    }
  }, [])

  // if (isLoading) {
  //   return <div>Loading...</div>; // Render a loading state until blogList is fetched
  // }

  return (
    !isLoading && <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", minHeight: "100vh", backgroundColor: "#f2f2f2" }} >
      {/* <Helmet>
      //   <title>
      //     {capitalizeFirstLetter(location.pathname.substring(1))} {location.pathname.substring(1) && '-'} My Builder
      //     ID
      //   </title>

       </Helmet> */}


      {/* Conditional rendering of Header */}
      {(window.location.pathname === "/" ||
        window.location.pathname === "/login" ||
        window.location.pathname === "/about" ||
        window.location.pathname === "/features" ||
        window.location.pathname === "/faqs" ||
        window.location.pathname === "/contact" ||
        window.location.pathname === "/blog" ||
        window.location.pathname === "/Version-2.0" ||
        window.location.pathname === "/Version-2.1" ||
        window.location.pathname === "/Version-2.2" ||
        blogList?.map((blog) => window.location.pathname === `/${blog.slug}`) ||
        window.location.pathname === "/Version-3.0.0" ||
        window.location.pathname === "/Version-3.2.0") ? (
        <Header />
      ) : (
        <></>
      )}
      {/* 
      {window.location.pathname !== "/" &&
        window.location.pathname !== "/login" &&
        !blogList?.map((blog) => window.location.pathname === `/${blog.slug}`) &&
        window.location.pathname !== "/about" &&
        window.location.pathname !== "/features" &&
        window.location.pathname !== "/faqs" &&
        window.location.pathname !== "/contact" &&
        window.location.pathname !== "/blog" &&
        window.location.pathname !== "/faqapp" ? (
        <CampaignHeader />
      ) : (
        <></>
      )} */}



      <Routes>
        <Route path="" element={<Home />} />

        <Route path="faqapp" element={<Faq />} />

        <Route path="Campaign-Home" element={
          <RequireAuth>
            <CampaignHome onTriggerAlert={(flag) => onTriggerAlert(flag)} setAlertMessage={setAlertMessage} />
          </RequireAuth>} />
        <Route path="Add-New-Campaign" element={
          <RequireAuth>
            <AddNewCampaign onTriggerAlert={(flag) => onTriggerAlert(flag)} setAlertMessage={setAlertMessage} />
          </RequireAuth>} />
        <Route path="Edit-Payment" element={
          <RequireAuth>
            <EditPayment onTriggerAlert={(flag) => onTriggerAlert(flag)} setAlertMessage={setAlertMessage} />
          </RequireAuth>} />
        <Route path="Edit-Profile" element={
          <RequireAuth>
            <EditProfile onTriggerAlert={(flag) => onTriggerAlert(flag)} setAlertMessage={setAlertMessage} />
          </RequireAuth>} />


        <Route path="Login" element={<LoginHub onTriggerAlert={(flag) => onTriggerAlert(flag)} setAlertMessage={setAlertMessage} />} />

        <Route path="about" element={<About />} />
        <Route path="features" element={<Features />} />
        <Route path="faqs" element={<Faq />} />
        <Route path="contact" element={<Contact />} />
        <Route path="blog" element={<Blog />} />

        {/* Dynamic Blog Routes */}
        {blogList?.map((blog) => (
          <Route
            key={blog.slug}
            path={blog.slug}
            // path={`/blog${blog.slug}`}
            element={<BlogPostContent post={blog} />} // Pass the blog post data to BlogPost component
          />
        ))}

        {/* Default redirects back to home if you put a random link that doesn't have a path */}
        {/* <Route path="*" element={<Navigate to="/" replace={true} />} /> */}
      </Routes>

      {/* <Footer /> */}
      {window.location.pathname != "/faqapp" ?
        <CampaignFooter /> : <></>}

      {/* Alert Popups */}
      <Alerts setAlertState={setAlertState} alertState={alertState} alertMessage={alertMessage} setAlertMessage={setAlertMessage} onTriggerAlert={(flag) => onTriggerAlert(flag)} />
    </div>
  )
}

export default App;
