import React from 'react';
import { useLocation } from "react-router-dom";
import { fetchBlogPostContent } from '../../api/MarketingApi';
import { formatDate } from '../../common/utils/utils';

const BlogPostContent = () => {

    const location = useLocation();
    const blogId = location.state?.id; // Access the id from the location state

    const [blogList, setBlogList] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    let FetchPosts = async () => {
        let resp = await fetchBlogPostContent({
            SearchList: [{
                id: blogId
            }]
        });

        setBlogList(resp)
        setIsLoading(false)
    }

    React.useEffect(() => {
        try {
            FetchPosts()
        }
        catch (error) {
            console.log(error);
        }
    }, [])

    // Use blogId to fetch additional details or for any other purpose
    return (
        !isLoading && blogList?.length > 0 && <section className="container padding-top padding-bottom" >
            {/* Render blog post */}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h2 style={{ textAlign: 'center' }}>{blogList[0].title}</h2>

                <img style={{
                    display: "block", // Ensures the image behaves as a block-level element
                    width: '100%', // Make the image full width on smaller screens
                    maxWidth: '600px', // Set a maximum width for larger screens
                    height: 'auto', // Maintain aspect ratio
                    margin: '40px auto 0px auto',
                    borderRadius: '4px',
                    objectFit: "cover"
                }}
                    src={blogList[0].imageUrl}
                />
                <div style={{ textAlign: 'center', color: 'gray', marginTop: '8px' }}>Published on {formatDate(blogList[0].datePosted)}</div>
            </div>

            <div className='padding-top' style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '100%' }}>
                    {blogList?.map((blog, index) =>
                        <div key={index} className="Container" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                    )}
                </div>
            </div>
        </section >
    )
}

export default BlogPostContent;
