import pageheader from "../../assets/images/page-header.jpg";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Blog.css";
import { Input } from "@progress/kendo-react-inputs";
import React, { useState, useEffect } from "react";
import {
    Card,
    CardTitle,
    CardBody,
    CardImage,
} from "@progress/kendo-react-layout";
import { fetchBlogPosts } from "../../api/MarketingApi";
import { formatDate } from "../../common/utils/utils";

export default function Blog() {
    const navigate = useNavigate();

    const [blogList, setBlogList] = useState(null);
    const [filteredBlogs, setFilteredBlogs] = useState(null); // Separate state for filtered blogs
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState(""); // State to hold search input

    const FetchPosts = async () => {
        let resp = await fetchBlogPosts({});
        setBlogList(resp);
        setFilteredBlogs(resp); // Set the filteredBlogs to be the same initially
        setIsLoading(false);
    };

    useEffect(() => {
        try {
            FetchPosts();
        } catch (error) {
            console.log(error);
        }
    }, []);

    const onFilterChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        // Filter the blogList based on searchQuery and update filteredBlogs
        if (blogList) {
            const filtered = blogList.filter((blog) =>
                blog.title.toLowerCase().includes(query) ||
                blog.excerpt.toLowerCase().includes(query)
            );
            setFilteredBlogs(filtered);
        }
    };

    if (isLoading) return "";

    return (
        <div className="latest-posts">
            <section
                className="hero-area bg_img"
                style={{
                    backgroundImage: `url(${pageheader})`,
                }}
            >
                <div className="container">
                    <h1 className="title m-0">Blog</h1>
                </div>
            </section>
            <div className="breadcrumb-section">
                <div className="container" style={{ paddingBottom: "0.1rem" }}>
                    <ul className="breadcrumb">
                        <li style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                            Home
                        </li>
                        <div style={{ margin: "0px 10px 0px 10px" }}> - </div>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>

            {blogList?.length > 0 ? (
                <div className="container" style={{ display: "flex", flexDirection: "column" }}>
                    {/* Use flexbox to align search box to the right */}
                    <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "22px" }}>
                        <Input
                            value={searchQuery}
                            onChange={onFilterChange}
                            style={{
                                border: "2px solid #ccc",
                                boxShadow: "inset 0px 0px 0.5px 0px rgba(0,0,0,0.1)",
                                width: "210px",
                                height: "30px",
                                margin: '36px 8px 0px 0px'
                            }}
                            placeholder="Search blog..."
                        />
                    </div>
                    <section
                        className=" padding-bottom"
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            gap: "50px",
                            justifyContent: "center",
                        }}
                    >
                        {filteredBlogs.map((blog, index) => {
                            return (
                                <div key={index} onClick={() => navigate(`/${blog.slug}`, { state: { id: blog.id } })}>
                                    <Card
                                        style={{
                                            minWidth: 380,
                                            maxWidth: 520,
                                            boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                                            minHeight: 450,
                                        }}
                                    >
                                        <CardImage
                                            src={blog.imageUrl}
                                            style={{
                                                height: "300px",
                                                maxWidth: "100%",
                                                objectFit: "cover",
                                            }}
                                            alt="Blog Image"
                                        />
                                        <div style={{ padding: "18px 14px 14px 14px" }}>
                                            <CardTitle style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                                <h5 style={{ color: "black" }}>{blog.title}</h5>
                                                <span style={{ marginTop: "4px", color: "gray", minWidth: "150px", textAlign: "right" }}>
                                                    {formatDate(blog.datePosted)}
                                                </span>
                                            </CardTitle>
                                            <CardBody style={{ color: "gray" }}>{blog.excerpt}</CardBody>
                                        </div>
                                    </Card>
                                </div>
                            );
                        })}
                    </section>
                </div>
            ) : (
                <h1 style={{ display: "flex", justifyContent: "center", marginTop: "15vh" }}>
                    No Blog Posts yet
                </h1>
            )}
        </div>
    );
}
